import React from 'react'
import { Link } from 'react-router-dom'

export function Navigation() {
    return (
        <nav className='flex justify-between items-center h-[50px] 
                        px-5 shadow-md bg-white text-black'>
            <Link to="/" className='text-sans text-4xl lg:ml-64'>Энкрипта</Link>
        </nav>
    )
}