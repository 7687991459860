import React from 'react'
import { Route, Routes } from 'react-router'
import { Footer } from './components/footer'
import { Navigation } from './components/Navigation'
import { About } from './pages/About'
import { Home } from './pages/Home'

function App() {
  return (
    <div className="h-screen">
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App
