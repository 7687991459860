import React from 'react'
import {
  InformationCircleIcon,
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
  DocumentIcon,
} from '@heroicons/react/outline'

const features = [
  {
    name: '143180, Московская обл. г.Звенигород, ул.Почтовая, д.8, офис 202',
    tel: '',
    email: '',
    icon: LocationMarkerIcon,
  },
  {
    name: 'пн-пт: 09:00 - 18:00',
    tel: '',
    email: '',
    icon: InformationCircleIcon,
  },

  {
    name: '',
    tel: '+7(495)488-71-74',
    email: '',
    icon: PhoneIcon,
  },

  {
    name: '',
    tel: '',
    email: 'info@encrypta.ru',
    icon: MailIcon,
  },
]

export function Footer() {
  return (
    <div className="w-full">
      <div className=" bg-red-700 flex justify-end ">
        <div className="mt-2 mb-2 mr-4 space-y-2 grid grid-cols-1 md:mr-64">
          <div className="relative">
            <div className="mt-0 mb-0">
              <div className="absolute flex items-center justify-center h-7 w-7 rounded-md bg-white text-red-700">
                <DocumentIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <p className="ml-10 text-lg leading-6 text-white mt-2 mb-2">
                <a href="https://docs.encrypta.ru">Публичные договоры</a>
              </p>
            </div>
          </div>

          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <div className="mt-0 mb-0">
                <div className="absolute flex items-center justify-center h-7 w-7 rounded-md bg-white text-red-700">
                  <feature.icon className="h-5 w-5" aria-hidden="true" />
                </div>
                {feature.name && (
                  <p className="ml-10 text-lg leading-6 text-white mt-2 mb-2">
                    {feature.name}
                  </p>
                )}
                {feature.email && (
                  <p className="ml-10 text-lg leading-6 text-white mt-2 mb-2">
                    <a href={`mailto: ${feature.email}`}>{feature.email}</a>
                  </p>
                )}
                {feature.tel && (
                  <p className="ml-10 text-lg leading-6 text-white mt-2 mb-2">
                    <a href={`tel: ${feature.tel}`}>{feature.tel}</a>
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className=" bg-red-700 flex justify-start">
        <div className="mt-4 mb-4 grid ml-4 md:mr-64"></div>
        <p className="text-xs text-gray-200 ">
          Copyright &copy; 2019-{new Date().getFullYear()} Encrypta LLC. All
          rights reserved.
        </p>
      </div>
    </div>
  )
}
